
import { defineComponent } from "vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Column from "primevue/column";
import Calendar from "primevue/calendar";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import SalesDialog from "@/components/Sales/SalesDialog.vue";
import MultiselectWithButton from "@/components/UI/MultiselectWithButton.vue";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import _debounce from "lodash/debounce";
import _uniq from "lodash/uniq";
import KanbanView from "@/components/Sales/KanbanView.vue";
import Utils from "@/utility/utils";
import EmailFileDialog from "@/components/UI/EmailFileDialog.vue";
import OrderSalesActionDialog from "../Pos/OrderSalesActionDialog.vue";
import store from "@/store";

// Types
import Customer from "@/types/customer";
import { RepItem } from "@/types/customer";
import SalesOrder from "@/types/salesorder";
import Pdf from "@/types/pdf";

// Services
import SalesService from "@/services/SalesService";
import CustomerService from "@/services/CustomerService";

const customerService = new CustomerService();
const salesService = new SalesService();

export default defineComponent({
  name: "SalesDataWrapper",
  components: {
    DataTable,
    Button,
    InputText,
    Column,
    Calendar,
    LoadingSpinner,
    SalesDialog,
    KanbanView,
    MultiselectWithButton,
    EmailFileDialog,
    OrderSalesActionDialog,
  },
  async created() {
    if (this.customerIndex !== undefined) {
      this.filters.cust_name.value = [this.getCurrentCustomer.name];
      this.selectedFilterCusts = [this.getCurrentCustomer];
      this.storeSortFilters();
    } else {
      this.filters.cust_name.value = [];
      this.selectedFilterCusts = [];
    }
    this.applyStoredSortFilters();
    if (this.$route.path.includes("sales/orders")) {
      this.isLoadingSales = true;
      this.fetchOrdersData(false, false);
    }
    if (!this.manuallyLoadOrders) {
      this.isLoadingSales = true;
      this.fetchOrdersData(false, false);
    }
    await this.fetchReps();
  },
  computed: {
    ...mapGetters({
      getOrders: "sales/getOrders",
      getPDFs: "sales/getPDFS",
      getLoadingPDFs: "sales/getLoadingPDFs",
      getCurrentCustomer: "customerInquiry/getCurrentCustomer",
      getCurrentCustTabIndex: "customerInquiry/getCurrentCustTabIndex",
      getClient: "session/getClient",
      getSoIds: "customerInquiry/getSoIds",
      getSalesOrdersSortFilters: "filters/getSalesOrdersSortFilters",
      getFilter: "session/getFilter",
      totalRecords: "sales/getTotalRecords",
      getPage: "sales/getPage",
      itemsPerPage: "sales/getItemsPerPage",
      getUser: "session/getUser",
      manuallyLoadOrders: "mrkControl/manuallyLoadOrders",
      salesOrderTypes: "mrkControl/salesOrderTypes",
      getReps: "rep/getReps",
    }),
    unassignedSaleType(): any {
      const unassigned_value = {
        valid_so_type: "",
        so_type_code_description: "Unassigned",
      };
      return [unassigned_value, ...this.salesOrderTypes];
    },
    displayTotalRecords(): number {
      return isNaN(this.totalRecords) ? 0 : this.totalRecords;
    },
    getFilteredReps(): any {
      const reps = this.getFilter("CUST", "REP");
      if (reps.length === 0) {
        return this.getReps;
      }
      return this.getReps.filter((rep: any) => {
        return reps.includes(rep.rep_id);
      });
    },
  },
  props: {
    customerIndex: Number,
    currentView: String,
    isResultView: {
      type: Boolean,
      default: true,
    },
    maxExportRows: {
      type: Number,
      required: true,
    },
    showSelectionCheckbox: { type: Boolean, default: false },
  },
  emits: ["update-badge", "lastUpdated", "update-total-records"],
  data() {
    return {
      isProduction: this.isProduction,
      printExportItems: [] as Array<any>,
      items: [] as Array<any>,
      selectedSalesID: "",
      selectedStatus: "",
      dateStart: "",
      dateEnd: "",
      selectedSale: null,
      selectedRow: null,
      id: "",
      showEmailDialog: false,
      showOrderSalesActionDialog: false,
      orderIdToEmail: "",
      fileName: "",
      showMail: false,
      showLoadExistingQuoteBtn: false,
      customerContacts: [] as Array<any>,
      selectedSalesStatus: null,
      readOnly: false,
      rowsPerPage: 10,
      page: 0,
      first: 0,
      firstRow: 0,
      isLoadingSales: false,
      sortOrder: -1,
      sortField: "date",
      formatedLists: [] as Array<any>,
      emptyTableLabel: "Orders have not been loaded",
      status: [
        { status: "New", initial: "N" },
        { status: "Back Order", initial: "B" },
        { status: "Closed", initial: "C" },
      ],
      statusLabel: {
        N: "New",
        B: "Back Order",
        C: "Closed",
      },
      filters: {
        cust_name: {
          value: [] as Array<string>,
          matchMode: FilterMatchMode.IN,
        },
        so_id: {
          value: "",
          matchMode: FilterMatchMode.EQUALS,
        },
        status: {
          value: [] as Array<string>,
          matchMode: FilterMatchMode.IN,
        },
        date: {
          value: [] as Array<Date>,
          matchMode: FilterMatchMode.IN,
        },
        po_number: {
          value: "",
          matchMode: FilterMatchMode.CONTAINS,
        },
        type: {
          value: [] as Array<string>,
          matchMode: FilterMatchMode.IN,
        },
        stamp_id: {
          value: "",
          matchMode: FilterMatchMode.EQUALS,
        },
        rep_name: {
          value: [] as Array<string>,
          matchMode: FilterMatchMode.IN,
        },
      },
      printColumns: [
        { field: "so_id", header: "ID", input: true },
        { field: "status", header: "Status", input: true },
        { field: "cust_name", header: "Customer", input: true },
        { field: "date", header: "Order Date", input: true },
        { field: "po_number", header: "Purchase", input: true },
        { field: "order_amount", header: "Order Total", input: true },
        { field: "stamp_id", header: "Created By", input: true },
        { field: "type", header: "Type", input: true },
        { field: "rep_items", header: "Reps", input: true },
      ],
      showSalesDialog: false,
      salesOrder: null as unknown,
      loadingSalesOrderPDFs: [] as Array<string>,
      selectedFilterCusts: [] as any[],
      searchedFilterCusts: [] as any[],
      selectedFilterType: [] as any[],
      selectedRepType: [] as any[],
      selectedFilterId: "",
      selectedPONumber: "",
      selectedCreatedBy: "",
      selectedRep: "",
      selectedFilterStatus: [] as any[],
      selectedFilterDate: undefined as any,
      dynamicFilters: {
        "po.number": {
          value: "",
          matchMode: FilterMatchMode.CONTAINS,
        },
        "stamp.id": {
          value: "",
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
    };
  },
  watch: {
    getCurrentCustomer() {
      if (
        this.getCurrentCustTabIndex == this.customerIndex &&
        this.getCurrentCustomer
      ) {
        this.filters.cust_name.value = [this.getCurrentCustomer.name];
        this.selectedFilterCusts = [this.getCurrentCustomer];
        this.filterCust();
      }
    },
    items() {
      if (!this.isResultView) {
        this.formatBoardList();
      }
    },
    isResultView() {
      this.clearAllFilters();

      if (!this.isResultView) {
        this.formatBoardList();
      }
      this.fetchOrdersData(false, false);
    },
  },
  mounted() {
    this.first = this.getPage * this.itemsPerPage;
    this.applyStoredSortFilters();
    this.selectedRepType = this.getFilter("CUST", "REP");
  },
  unmounted() {
    this.clearAndResetOrders();
  },
  inject: ["isProduction"],
  methods: {
    ...mapActions({
      fetchOrdersRecords: "sales/fetchOrdersRecords",
      clearAndResetOrders: "sales/clearAndResetOrders",
      getOrderPDF: "sales/getOrderPDF",
      openPDF: "sales/openPDF",
      addOpenedSalesOrder: "salesInquiry/addOpenedSalesInquiry",
      addNotification: "notification/add",
      setSalesOrderSortFilters: "filters/setSalesOrderSortFilters",
      clearPrintData: "printableDatatable/clearData",
      setPrintData: "printableDatatable/setData",
      setPrintDefaultColumns: "printableDatatable/setDefaultColumns",
      setPrintAvaialbleColumns: "printableDatatable/setAvaialbleColumns",
      fetchReps: "rep/fetchReps",
    }),
    getTypeCodeDescription(typeCode: string) {
      const unassigned_value = {
        valid_so_type: "",
        so_type_code_description: "Unassigned",
      };
      const order_types = [];
      order_types.push(unassigned_value, ...this.salesOrderTypes);
      if (order_types.find((type: any) => type.valid_so_type === typeCode)) {
        return order_types.find((type: any) => type.valid_so_type === typeCode)
          ?.so_type_code_description;
      } else if (typeCode === null || typeCode === undefined) {
        return "";
      } else {
        return typeCode;
      }
    },
    setFirstRow(row: any) {
      this.firstRow = row;
    },
    formatRepItems(repItems: RepItem[]) {
      return (
        repItems &&
        repItems
          .filter((item) => item.rep_name)
          .map((item) => item.rep_name)
          .join(",\n")
      );
    },
    applyStoredSortFilters() {
      const filters = this.getSalesOrdersSortFilters.filters;
      for (const filter in filters) {
        switch (filter) {
          case "so_id":
            this.selectedFilterId = filters[filter];
            break;
          case "status":
            this.selectedFilterStatus = filters[filter];
            break;
          case "date":
            this.selectedFilterDate = filters[filter];
            break;
          case "cust_name":
            this.selectedFilterCusts = filters[filter];
            this.handleCustomerSelectFilter(filters[filter]);
            break;
        }
      }
      this.sortField = this.getSalesOrdersSortFilters.sort.sortField;
      this.sortOrder = this.getSalesOrdersSortFilters.sort.sortOrder;
    },
    storeSortFilters() {
      const filterCusts = {
        value: this.selectedFilterCusts,
      };
      const sortFiltersToStore = Utils.getSortFiltersToStore({
        filters: { ...this.filters, cust_name: filterCusts },
        sortField: this.sortField,
        sortOrder: this.sortOrder,
      });
      this.setSalesOrderSortFilters(sortFiltersToStore);
    },
    showRow(event: any) {
      this.salesOrder = event;
      this.openSales(true);
      this.id = event.so_id;
    },
    rowClick(event: any) {
      // don't fire for selection checkbox
      if (
        event.originalEvent &&
        event.originalEvent.target.className.includes("p-checkbox-icon")
      ) {
        return;
      }
      if (this.$route.path.includes("pos/customers")) {
        this.showOrderSalesActionDialog = true;
        this.salesOrder = event.data;
        return;
      }
      if (this.isProduction) {
        let itemToEdit = event;
        if (event.data) {
          itemToEdit = event.data;
        }
        this.showRow(itemToEdit);
      } else {
        event.data["saleType"] = "orders";
        this.addOpenedSalesOrder(event.data);
        this.$router.push(`/sales/orders/${event.data.so_id}`);
      }
    },
    openSales(edit: boolean) {
      this.showSalesDialog = true;
      this.readOnly = edit;
      this.showLoadExistingQuoteBtn = false;
    },
    onPage(event: any) {
      this.rowsPerPage = event.rows;
      this.setFirstRow(event.first);
      store.commit("sales/NEXT_PAGE", event.page);
      store.commit("sales/SET_RANGE_START", event.first + 1);
      store.commit("sales/SET_RANGE_END", event.first + event.rows);
      this.fetchOrdersData(true, false);
    },
    handleFilter(event: any) {
      store.commit("sales/NEXT_PAGE", 1);
      store.commit("sales/SET_RANGE_START", 1);
      store.commit("sales/SET_RANGE_END", 50);
      store.commit("sales/SET_FILTER_VALUES", event.filters);
      this.fetchOrdersData(false, false);
    },
    sortData(event: any) {
      this.sortField = event.sortField;
      this.sortOrder = event.sortOrder;
      this.rowsPerPage = 10;

      this.fetchOrdersData(false, false);
    },
    handleCustomerSelectFilter: _debounce(async function (event) {
      const customers = await customerService.getAllCustomers({
        selection: event.value,
        activeOnly: "Y",
      });
      // @ts-expect-error becuas of exploit this
      this.searchedFilterCusts = _uniq([
        // @ts-expect-error becuas of exploit this
        ...customers.cust_items.sort((a, b) => a.name.localeCompare(b.name)),
        // @ts-expect-error becuas of exploit this
        ...this.selectedFilterCusts,
      ]);
    }, 1000),
    getCustomersFilter() {
      return this.selectedFilterCusts.map((cust) => {
        return cust.cust_id;
      });
    },
    async fetchOrdersData(addOrder: boolean, forPrintExport: boolean) {
      const reps = this.getFilter("CUST", "REP");

      if (reps.length > 0 && this.selectedRepType.length === 0) {
        this.selectedRepType = reps;
      }

      let sortOrderRecordsBy = "";

      let sortBy = this.sortField.replaceAll("_", ".");

      if (this.sortOrder === -1) {
        sortOrderRecordsBy = "DEC";
      }

      // Filter logic
      if (this.currentView === "customers") {
        if (this.selectedFilterId) {
          if (
            !this.getSoIds(this.customerIndex).includes(this.selectedFilterId)
          ) {
            this.isLoadingSales = false;
            return;
          }
        } else {
          if (!this.getSoIds(this.customerIndex).join(" ")) {
            return;
          }
        }
      }

      this.isLoadingSales = true;
      this.filters.so_id.value = this.selectedFilterId;
      this.filters.status.value = [...this.selectedFilterStatus];
      this.filters.cust_name.value = this.selectedFilterCusts.map((cust) => {
        return cust.name;
      });

      this.filters.date.value = [];
      if (this.selectedFilterDate) {
        if (this.selectedFilterDate[0] != null)
          this.dateStart = this.selectedFilterDate[0];
        if (this.selectedFilterDate[1] != null)
          this.dateEnd = this.selectedFilterDate[1];

        this.dateStart = this.formatStringDate(this.dateStart);
        this.dateEnd = this.formatStringDate(this.dateEnd);

        if (this.dateStart)
          this.filters.date.value.push(new Date(this.selectedFilterDate[0]));
        if (this.dateEnd)
          this.filters.date.value.push(new Date(this.selectedFilterDate[1]));
      }
      this.storeSortFilters();

      const filterStatus = this.selectedFilterStatus;

      await this.fetchOrdersRecords({
        ids: this.filters.so_id.value,
        status: filterStatus,
        custs: this.getCustomersFilter(),
        sortBy: sortBy,
        rep: this.selectedRepType,
        sortOrder: sortOrderRecordsBy,
        addOrder: addOrder,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd || this.dateStart,
        correls:
          "cust_name rep_name bill_to_name hold_code_desc order_amount cust_resale currency_code exchange_rate pending_ship li_qty_bal li_sch_qty_bal",
        disableSortedSample: "Y",
        type: this.selectedFilterType,
        user: this.getUser.user_id,
        forPrintExport: forPrintExport,
        correl_criteria: {
          ...this.dynamicFilters,
          "po.number": {
            value: this.selectedPONumber,
            matchMode: FilterMatchMode.CONTAINS,
          },
          "stamp.id": {
            value: this.selectedCreatedBy,
            matchMode: FilterMatchMode.CONTAINS,
          },
        },
      })
        .then((resp) => {
          this.$emit("lastUpdated", Date.now());
          this.items = resp.data;
          this.printExportItems = this.items.map((item: SalesOrder) => {
            let repItems = this.formatRepItems(item.rep_items as any[]);
            return {
              so_id: item.so_id,
              status: Utils.formatStatus(item.status),
              cust_name: item.cust_name,
              date: this.formatStringDate(item.date),
              po_number: item.po_number,
              stamp_id: item.stamp_id,
              type: item.type,
              rep_items: repItems,
              order_amount: this.formatCurrency(item.order_amount),
            };
          });
          this.$emit("update-total-records", this.totalRecords);
          this.$emit("update-badge", "so_ids", this.totalRecords);

        })
        .catch(() => {
          this.isLoadingSales = false;
        })
        .finally(() => {
          this.isLoadingSales = false;
          this.emptyTableLabel = "No orders have been found";
        });
      if (!addOrder) {
        this.page = 1;
        this.first = 0;
      }
    },
    async printTable() {
      // Block printing if there are no records
      if (this.totalRecords === 0) {
        const notification = {
          message: `No records to print or export.`,
          type: "error",
        };
        this.addNotification(notification);
        return;
      }

      // Notify user if there are too many records to export
      if (this.totalRecords > this.maxExportRows) {
        const notification = {
          message: `Please filter the results before printing.`,
          type: "error",
        };
        this.addNotification(notification);
        return;
      }

      this.clearPrintData();
      try {
        await this.fetchOrdersData(false, true);
        // Set the print data only after the orders data is fetched
        this.setPrintData(this.printExportItems);
        this.setPrintDefaultColumns(this.printColumns);
        this.setPrintAvaialbleColumns(this.printColumns);

        // Open the printable view in a new tab
        window.open("/printable-view?print=1&showSelection=1", "_blank");
      } catch (error) {
        // Handle errors from the data fetching process
        console.error("Error fetching order data for printing: ", error);
        const notification = {
          message: `Failed to fetch order data. Please try again.`,
          type: "error",
        };
        this.addNotification(notification);
      }
    },
    clearAllFilters() {
      if (this.customerIndex !== undefined) {
        this.filters.cust_name.value = [this.getCurrentCustomer.name];
        this.selectedFilterCusts = [this.getCurrentCustomer];
        this.storeSortFilters();
      } else {
        this.filters.cust_name.value = [];
        this.selectedFilterCusts = [];
      }
      this.selectedFilterId = "";
      this.filters.so_id.value = "";
      this.selectedFilterStatus = [];
      this.dateStart = this.dateEnd = "";
      this.selectedFilterDate = null;
      this.filters.date.value = [];
      this.selectedPONumber = "";
      this.filters.po_number.value = "";
      this.selectedCreatedBy = "";
      this.filters.stamp_id.value = "";
      this.selectedFilterType = [];
      this.selectedRepType = this.getFilter("CUST", "REP");
    },
    clearAllFiltersAndFetch() {
      this.clearAllFilters();
      this.fetchOrdersData(false, false);
    },
    clearSalesSearchBar() {
      this.selectedFilterId = "";
      this.filters.so_id.value = "";
      this.fetchOrdersData(false, false);
    },
    clearPONumberSearchBar() {
      this.selectedPONumber = "";
      this.filters.po_number.value = "";
      this.fetchOrdersData(false, false);
    },
    clearCreatedBySearchBar() {
      this.selectedCreatedBy = "";
      this.filters.stamp_id.value = "";
      this.fetchOrdersData(false, false);
    },
    clearStatusSearchBar() {
      this.selectedFilterStatus = [];
      //(((this.filters as SalesOrder)['status'] as unknown) as FilterConstraints).value = null;
      this.fetchOrdersData(false, false);
    },
    clearTypeSearchBar() {
      this.selectedFilterType = [];
      this.fetchOrdersData(false, false);
    },
    clearRepTypeSearchBar() {
      this.selectedRepType = this.getFilter("CUST", "REP");
      this.fetchOrdersData(false, false);
    },
    clearDateFilter() {
      this.dateStart = this.dateEnd = "";
      this.selectedFilterDate = null;
      this.filters.date.value = [];
      this.fetchOrdersData(false, false);
    },

    clearCustomerSearch() {
      this.filters.cust_name.value = [];
      this.selectedFilterCusts = [];
      this.fetchOrdersData(false, false);
    },

    filterCust() {
      this.isLoadingSales = true;
      this.filters.cust_name.value = this.selectedFilterCusts.map((cust) => {
        return cust.name;
      });
      this.searchedFilterCusts = [...this.selectedFilterCusts];
      this.fetchOrdersData(false, false);
    },
    formatStringDate(dueDate: string) {
      if (!dueDate || dueDate == null) return "";
      const reformatedDueDate = Utils.formatDate(dueDate);

      return reformatedDueDate;
    },
    formatCurrency: (value: string) => {
      return (+value).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    getSalesOrderPDF(id: string) {
      this.getOrderPDF({ client: this.getClient, recordId: id });
    },
    salePDFIcon(id: string) {
      let downloaded: Array<Pdf> = this.getPDFs;
      let downloading: boolean =
        this.getLoadingPDFs.find((i: string) => i === id) !== undefined;
      return {
        "pi pi-download":
          downloaded.find((i: Pdf) => i.id === id) === undefined &&
          !downloading,
        "pi pi-spin pi-spinner": downloading,
        "pi pi-file-pdf":
          downloaded.find((i: Pdf) => i.id === id) !== undefined &&
          !downloading,
      };
    },
    formatBoardList() {
      let controlStatusList: Array<any> = [];
      controlStatusList = JSON.parse(JSON.stringify(this.status));
      controlStatusList.unshift({
        status: "Unassigned",
      });
      controlStatusList.forEach((element) => (element.items = []));

      this.items.forEach((item: any) => {
        const statusIndex = controlStatusList.findIndex(
          (element) => element.initial === item.status,
        );
        if (statusIndex >= 0) {
          controlStatusList[statusIndex].items.push(item);
        } else {
          controlStatusList[0].items.push(item);
        }
      });
      this.formatedLists = controlStatusList;
    },
    onStatusChange(event: any) {
      let quoteWithNewStatus = JSON.parse(
        JSON.stringify(event.item.added.element),
      );
      quoteWithNewStatus.status = event.status;
      salesService.putOrder(quoteWithNewStatus.so_id, quoteWithNewStatus);
    },
    async handleEmailPDF(salesOrder: SalesOrder) {
      const orderId = salesOrder.so_id;
      this.orderIdToEmail = orderId;

      await customerService
        .getCustomer(salesOrder.sold_to, "", "contact_name contact_email")
        .then((response: any) => {
          const customer = response as Customer;
          this.customerContacts = customer.contact_id_items;
        })
        .catch((error) => {
          this.addNotification({
            message: `Customer contacts could not load: ${error}`,
            type: "error",
          });
        })
        .finally(() => {
          this.showEmailDialog = true;
        });
    },
    sendEmail(data: any) {
      salesService
        .getOrderPDF(this.orderIdToEmail, this.getClient, data)
        .then((response) => {
          if (response === "success") {
            this.addNotification({
              message: `Sales Order has been emailed successfully`,
              type: "success",
            });
          } else {
            this.addNotification({
              message: `Sales Order was not sent`,
              type: "error",
            });
          }
        })
        .catch((error) => {
          this.addNotification({
            message: `Sales Order could not be sent: ${error}`,
            type: "error",
          });
        });
    },
  },
});
